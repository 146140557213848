import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { AuthContextProvider } from './context/AuthContext';
import { EnvContextProvider } from './context/EnvContext';
import { SyllabusContextProvider } from './context/SyllabusContext';
import { MentorContextProvider } from './context/MentorContext';
import { CourseContextProvider } from './context/CourseListContext';
import { ContentContextProvider } from './context/ContentContext';
import { AssignmentContextProvider } from './context/AssignmentContext';
import { ProjectContextProvider } from './context/ProjectContext';
import { CartContextProvider } from './context/CartContext';
import { WishListContextProvider } from './context/WishListContext';
import { CouponContextProvider } from './context/CouponContext';
import { SiteManagerContextProvider } from './context/SiteManagerContext';
import { extendTheme } from "@chakra-ui/react";
import { PaymentContextProvider } from './context/PaymentContext';
import AllContextProviders from './context/AllContextProviders';

// import DisableDevtool from 'disable-devtool';

// DisableDevtool();
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  components: {
    Toast: {
      baseStyle: {
        container: {
          position: 'top-right',
          mt: '62px',
        },
      },
    },
  },
  toast: {
    defaultOptions: {
      duration: 1000,
    },
  },
});
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <BrowserRouter>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <AllContextProviders>
        <App />
      </AllContextProviders>

    </ChakraProvider>
  </BrowserRouter>
);
