import React, { useContext, useEffect, useState } from 'react';
import AssignmentContext from '../../../context/AssignmentContext';
import { Button, HStack, Heading, Text, VStack } from '@chakra-ui/react';
import EnvContext from '../../../context/EnvContext';

const showImage = () => {
  return <p>hiiii</p>;
};

export const AboutQnA = () => {
  /* The code snippet is defining a React functional component named `AboutQnA`. Within this component,
  it is using the `useContext` hook from React to access the context values provided by
  `AssignmentContext` and `EnvContext`. */
  const { qa } = React.useContext(AssignmentContext);
  const { backendUrl } = useContext(EnvContext);
  const [imageName, setImageName] = useState([]);

  /**
   * The function `handleShowImage` extracts filenames from image paths and sets them using a state
   * setter function.
   */
  const handleShowImage = index => {
    if (qa.options && qa.options.image) {
      const imageNames = qa.options.image.map(path => {
        // Split the path by '\' and get the last element
        const parts = path.split('\\');
        const filenameWithTimestamp = parts[parts.length - 1];

        // Split the filename by '-' and get the last element
        const filenameParts = filenameWithTimestamp.split('-');
        const filename = filenameParts[filenameParts.length - 1];

        return filename; // Return the filename from map
      });

      // Assuming setImageName is a state setter function
      setImageName(imageNames); // Set the image names
    }
  };

  /* The `useEffect(() => { if (qa) { handleShowImage(); } }, [qa]);` code snippet is utilizing the
  `useEffect` hook in React. This hook is used to perform side effects in function components. */
  useEffect(() => {
    if (qa) {
      handleShowImage();
    }
  }, [qa]);
  /* The code snippet `useEffect(() => { if (imageName) console.log(imageName); }, [imageName]);` is
  utilizing the `useEffect` hook in React. This hook is used to perform side effects in function
  components. */
  useEffect(() => {
    if (imageName) console.log(imageName);
  }, [imageName]);
  return (
    <>
      {qa.questionType ? (
        <VStack alignItems={'flex-start'} spacing={3} p={4} width={'100%'}>
          <VStack>
            <Text fontWeight={600}>Question :</Text>
            <Text>{qa?.question.text}</Text>
            <img src={`${backendUrl}${qa?.question.image}`}></img>
          </VStack>

          <HStack>
            <Text fontWeight={600}>Description :</Text>
            <Text>{qa?.description}</Text>
          </HStack>
          <HStack>
            <Text fontWeight={600}>No.of Attempts :</Text>
            <Text>{qa?.noOfAttempts}</Text>
          </HStack>
          <VStack>
            <Text fontWeight={600}>Options :</Text>
            {qa.options.text.length > 0 &&
              qa.options.text.map((textOption, i) => <Text>{textOption}</Text>)}
            {qa.options.image.length > 0 &&
              qa.options.image.map(
                (imageOption, i) =>
                  imageName &&
                  imageName.length > 0 && (
                    <>
                      <VStack>
                        <HStack>
                          <li>{imageName[i]}</li>
                        </HStack>

                        <img
                          key={i}
                          src={`${backendUrl}${imageOption}`}
                          alt="option"
                        />
                      </VStack>
                    </>
                  )
              )}
          </VStack>


          <VStack>
            <Text fontWeight={600}>Answers :</Text>
            {qa.answer.length > 0 &&
              qa.answer.map((answer, i) => <li key={i}>{answer}</li>)}

          </VStack>

        </VStack>
      ) : (
        <Text fontWeight={700}>First select any content</Text>
      )}
    </>
  );
};
