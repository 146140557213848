import { createContext, useContext, useState, useEffect } from 'react';
import EnvContext from './EnvContext';
import toast from 'react-hot-toast';
import axios from 'axios';
import CourseContext from './CourseListContext';
import CryptoJS from 'crypto-js';
import AuthContext from './AuthContext';

const ContentContext = createContext();

export const ContentContextProvider = ({ children }) => {
  const [contents, setContents] = useState([]);
  const [content, setContent] = useState({});
  const [decryptedText, setDecryptedText] = useState('');
  const [decryptedVideo, setDecryptedVideo] = useState('');
  const [contentId, setContentId] = useState(null);
  const [videoDuration, setVideoDuration] = useState('');
  const [lastWatchedPoint, setLastWatchedPoint] = useState(0);
  const { courseId, courseData } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const [token, setToken] = useState(null);
  const [apiCalled, setApiCalled] = useState(true);
  const [scorecard, setScoreCard] = useState({});
  const [assign, setAssign] = useState([]);
  const CRYPTO_KEY = process.env.REACT_APP_CRYPTO_KEY;
  const [index, setIndex] = useState(null);
  const [projectChartData, setProjectChatData] = useState([]);
  const [watchedContentChatData, setWatchedContentChatData] = useState([]);
  const [assignmentChatData, setAssignmentCharData] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [rank, setRank] = useState(0);
  const [hasMarkAsRead, sethasMarkAsRead] = useState(false);

  const COLORS = ['#2196F3', '#9E9E9E', '#4CAF50', '#F44336', '#FF8012'];
  const CONTENT_COLORS = ['#2196F3', '#9E9E9E'];
  const ASSIGNMENT_COLORS = ['#4CAF50', '#F44336', '#9E9E9E'];

  useEffect(() => {
    if (projectChartData && watchedContentChatData && assignmentChatData) {
      console.log('projectChartData', projectChartData);
      console.log('watchedContentChatData', watchedContentChatData);
      console.log('assignmentChatData', assignmentChatData);
    }
  }, [projectChartData, watchedContentChatData, assignmentChatData]);

  useEffect(() => {
    if (scorecard && Array.isArray(scorecard.assignment)) {
      // console.log("scorecard.assignment",scorecard.assignment)
      // setPrevAnsw(scorecard.assignment);
      const correctAnswers = scorecard.assignment.filter(
        item => item.isCorrectAnswer === true
      );
      const uniqueWrongAnswers = [
        ...new Set(
          scorecard.assignment
            .filter(item => !item.isCorrectAnswer)
            .map(item => item?.question?._id)
        ),
      ];

      // console.log(
      //   'correctAnswers',
      //   correctAnswers,
      //   correctAnswers && Array.isArray(correctAnswers) && correctAnswers.length
      // );
      // console.log(
      //   'uniqueWrongAnswers',
      //   uniqueWrongAnswers,
      //   uniqueWrongAnswers &&
      //     Array.isArray(uniqueWrongAnswers) &&
      //     uniqueWrongAnswers.length
      // );
      // console.log(
      //   'not attempted',
      //   correctAnswers &&
      //     Array.isArray(correctAnswers) &&
      //     uniqueWrongAnswers &&
      //     Array.isArray(uniqueWrongAnswers) &&
      //     (correctAnswers.length + uniqueWrongAnswers.length) !== courseData?.totalQnaCount &&
      //     courseData?.totalQnaCount -
      //       (correctAnswers.length + uniqueWrongAnswers.length)
      // );
      // Now you can use correctAnswers array for further processing
      setAssign(correctAnswers);
      setAssignmentCharData([
        {
          name: 'Corerct',
          value:
            correctAnswers &&
            Array.isArray(correctAnswers) &&
            correctAnswers.length,
        },
        {
          name: 'Wrong',
          value:
            uniqueWrongAnswers &&
            Array.isArray(uniqueWrongAnswers) &&
            uniqueWrongAnswers.length,
        },
        {
          name: 'Not Attempted',
          value:
            correctAnswers &&
            Array.isArray(correctAnswers) &&
            uniqueWrongAnswers &&
            Array.isArray(uniqueWrongAnswers) &&
            correctAnswers.length + uniqueWrongAnswers.length <=
              courseData?.totalQnaCount
              ? courseData?.totalQnaCount -
                (correctAnswers.length + uniqueWrongAnswers.length)
              : 0,
        },
      ]);
    }
  }, [scorecard]);

  // useEffect(() => {
  //   if (assign) console.log('correct ans', assign);
  // }, [assign]);
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const [latestSlNo, setUpdatedSlNo] = useState(null);

  // Function to update the array
  const updateContents = () => {
    const len = contents.length;
    if (len > 0) {
      const lastLen = len - 1;
      const lastSl_no = contents[lastLen].sl_no;
      const newSl_no = Math.floor(lastSl_no);
      setUpdatedSlNo(newSl_no + 1);
    }
  };
  useEffect(() => {
    if (contents) updateContents();
    console.log('contents:', contents);
  }, [contents]);
  const fetchAllContent = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // let loadingToast;
    try {
      // loadingToast = toast.loading('Contents are loading up !!', {
      //   position: 'top-right',
      //   style: {
      //     marginTop: '62px',
      //   },
      // });
      await axios
        .get(`${backendUrl}/course/content/all?courseId=${courseId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('res.data.data', res.data.data);
          setContents(res.data.data);
          // toast.success(res.data.message, {
          //   position: 'top-right',
          //   style: {
          //     marginTop: '62px',
          //   },
          // });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      // if (loadingToast) {
      //   toast.dismiss(loadingToast);
      // }
    }
  };
  const fetchContent = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    // let loadingToast;
    try {
      // loadingToast = toast.loading('Contents are loading up !!', {
      //   position: 'top-right',
      //   style: {
      //     marginTop: '62px',
      //   },
      // });
      await axios
        .get(`${backendUrl}/course/content?contentId=${contentId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('content', res.data.data);
          setContent(res.data.data);
          // toast.success(res.data.message, {
          //   position: 'top-right',
          //   style: {
          //     marginTop: '62px',
          //   },
          // });
        });
    } catch (err) {
      if (err.response.data.message)
        toast.error(`${err.response.data.message}`, {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
      else
        toast.error('Something went wrong', {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        });
    } finally {
      // Close the loading state
      // if (loadingToast) {
      //   toast.dismiss(loadingToast);
      // }
    }
  };

  const handleToggleLike = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    if (contentId) {
      try {
        await axios
          .put(
            `${backendUrl}/course/content/toogle_like?contentId=${contentId}`,
            null,
            {
              headers: headers,
            }
          )
          .then(res => {
            console.log('toogle_like done', res.data.data);
            fetchContent();
            // setScoreCard(res.data.data)
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchEncryptedContent = async id => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log('fetchEncryptedContent api hit');

    try {
      await axios
        .get(`${backendUrl}/course/content/vod?contentId=${id}`, {
          headers: headers,
        })
        .then(res => {
          console.log('encrypt content data', res.data);
          if (res.data.data.video) {
            const bytes = CryptoJS.AES.decrypt(res.data.data.video, CRYPTO_KEY);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            console.log('original video link', originalText);
            // console.log("originalText",originalText,"path",res.data.data.video,"bytes",bytes)
            setDecryptedVideo(originalText);
          } else {
            console.log('pdf data', res.data.data.docContent);
            const bytes = CryptoJS.AES.decrypt(
              res.data.data.docContent,
              CRYPTO_KEY
            );
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            console.log('original pdf text', originalText);
            setDecryptedText(originalText);
          }

          if (!res.data.data.lastWatchedPoint) {
            setLastWatchedPoint(0);
          } else {
            const lastWatchedPointNumber = +res.data.data.lastWatchedPoint;
            setLastWatchedPoint(lastWatchedPointNumber);
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addReadingStatus = async postData => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .post(`${backendUrl}/user/rs/curr_status`, postData, {
          headers: headers,
        })
        .then(res => {
          console.log(res);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const markAsRead = async (userId, courseId, contentId) => {
    if (hasMarkAsRead) return;
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .put(
          `${backendUrl}/user/rs/mark_as_read?userId=${userId}&courseId=${courseId}&contentId=${contentId}`,
          null,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log(res);
          sethasMarkAsRead(true);
          fetchUserScoreboard(userId, courseId);
          fetchLeaderBoard();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserScoreboard = async (userId, courseId) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(
          `${backendUrl}/user/rs/scorecard?userId=${userId}&courseId=${courseId}`,
          {
            headers: headers,
          }
        )
        .then(res => {
          console.log('scorecard fetched', res.data);
          setScoreCard(res.data.data);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserCurrentReadingStatus = async userId => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      await axios
        .get(`${backendUrl}/user/rs/curr_status?userId=${userId}`, {
          headers: headers,
        })
        .then(res => {
          console.log('CurrentReadingStatus fetched', res.data.data);
          // setScoreCard(res.data.data)
        });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLeaderBoard = async () => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.get(
        `${backendUrl}/user/rs/leaderboard?courseId=${courseId}`,
        {
          headers: headers,
        }
      );
      setLeaderBoard(response.data.leaderboard);
      setRank(response.data.userRank);
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : 'Something Went Wrong',
        {
          position: 'top-right',
          style: {
            marginTop: '62px',
          },
        }
      );
    }
  };
  return (
    <ContentContext.Provider
      value={{
        setDecryptedText,
        decryptedText,
        fetchEncryptedContent,
        fetchAllContent,
        latestSlNo,
        contents,
        setContentId,
        setToken,
        contentId,
        fetchContent,
        content,
        addReadingStatus,
        lastWatchedPoint,
        markAsRead,
        setVideoDuration,
        videoDuration,
        setApiCalled,
        apiCalled,
        fetchUserScoreboard,
        scorecard,
        decryptedVideo,
        setDecryptedVideo,
        fetchUserCurrentReadingStatus,
        assign,
        setIndex,
        index,
        projectChartData,
        setProjectChatData,
        COLORS,
        watchedContentChatData,
        setWatchedContentChatData,
        CONTENT_COLORS,
        assignmentChatData,
        ASSIGNMENT_COLORS,
        setLeaderBoard,
        leaderBoard,
        fetchLeaderBoard,
        rank,
        handleToggleLike,
        sethasMarkAsRead,
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

export default ContentContext;

export const useContent = () => useContext(ContentContext);
