import React, { useContext, useEffect } from 'react';

import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Text,
  IconButton,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import AssignmentContext from '../../../context/AssignmentContext';
import { AllAssignments } from './AllAssignments';
import ContentContext from '../../../context/ContentContext';
import { AboutQnA } from './AboutQnA';
import AddQuestionForm from '../../../components/forms/AddQuestionForm';
import { useNavigate } from 'react-router-dom';

export const ManageAssignments = () => {
  /* The line `const { setToken, fetchAllAssignments } = useContext(AssignmentContext);` is using the
  `useContext` hook from React to access the values of `setToken` and `fetchAllAssignments` from the
  `AssignmentContext`. */
  const { setToken, fetchAllAssignments } = useContext(AssignmentContext);
  /* The line `const { contentId } = useContext(ContentContext);` is using the `useContext` hook from
  React to access the value of `contentId` from the `ContentContext`. By destructuring the
  `contentId` from the context, the component can access and use the value stored in the
  `ContentContext` within the `ManageAssignments` component. This allows the component to retrieve
  specific data or functionality related to the content ID from the context and use it within the
  component's logic. */
  const { contentId } = useContext(ContentContext);
  /* The line `const token = localStorage.getItem('token');` is retrieving the value stored in the
  'token' key from the browser's localStorage. This value is then assigned to the variable `token`
  for further use in the component.localStorage provides a way to store key-value pairs locally in
  the user's browser, allowing data to persist even after the page is reloaded or closed. */
  const token = localStorage.getItem('token');
  /* The `useEffect` hook in the code snippet is used to perform side effects in function components. In
  this specific case: */
  useEffect(() => {
    if (token) {
      setToken(token);
      fetchAllAssignments(contentId);
    }
  }, [token]);

  /* The line `const role = localStorage.getItem('role');` is retrieving the value stored in the 'role'
  key from the browser's localStorage. This value is then assigned to the variable `role` for
  further use in the component. The purpose of this line is to fetch the role information stored in
  the browser's localStorage. This role information is likely used to determine the user's role or
  permissions within the application. In this specific context, the role information is being
  checked to see if it is equal to "admin" in a later `useEffect` hook to conditionally redirect the
  user based on their role. */
  const role = localStorage.getItem('role');

  /* The line `const navigate = useNavigate();` is using the `useNavigate` hook from the
  `react-router-dom` library to obtain the `navigate` function. This function allows for programmatic
  navigation within a React component. In this specific context, it is being used to redirect the
  user to a different route based on certain conditions. */
  const navigate = useNavigate();

  /* The `useEffect` hook in the provided code snippet is checking the value of the `role` variable
  obtained from the browser's localStorage. If the `role` is not equal to "admin", it triggers the
  `navigate('/dashboard')` function, which redirects the user to the '/dashboard' route. */
  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  return (
    <Box px={'5rem'}>
      <HStack w={'100%'} p={4} spacing={10} alignItems={'flex-start'}>
        <VStack
          width={'40%'}
          boxShadow={'lg'}
          rounded={'md'}
          alignItems={'center'}
          justifyContent={'center'}
          spacing={2}
        >
          <AllAssignments />
        </VStack>

        <VStack w={'50%'}>
          <Tabs w={'100%'}>
            <TabList>
              <Tab>About</Tab>
              <Tab>Add Question</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <AboutQnA />
              </TabPanel>
              <TabPanel>
                <AddQuestionForm />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </VStack>
      </HStack>
    </Box>
  );
};
