import { HStack, VStack, Text, Divider, Tabs, TabList, TabPanels, Tab, TabPanel, Box, IconButton, Button } from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import AddCoupon from '../../../components/forms/AddCoupon';
import UpdateCoupon from '../../../components/forms/UpdateCoupon';
import CouponContext from '../../../context/CouponContext';
import { DeleteCoupon } from '../../../components/alert/DeleteCoupon';
import { DeleteIcon } from '@chakra-ui/icons';
import AboutCoupon from './AboutCoupon';
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
function Coupon() {
  /* The line `const { coupons, getCoupon, setCouponId, couponId, getAboutCoupon } =
  useContext(CouponContext)` is using the `useContext` hook in React to access the values provided by
  the `CouponContext` context. */
  const { coupons, getCoupon, setCouponId, couponId, getAboutCoupon } = useContext(CouponContext)
  /* The line `const [selectedTab, setSelectedTab] = useState(0);` in the provided code snippet is
  utilizing the `useState` hook in React to create a piece of state named `selectedTab` with an
  initial value of `0`. */
  const [selectedTab, setSelectedTab] = useState(0);

  /**
   * The function `handleButtonClick` sets the selected tab based on the value passed as an argument.
   * @param value - The `value` parameter in the `handleButtonClick` function likely represents the tab
   * that was clicked or selected by the user. This value is then used to update the selected tab in the
   * application, with index 2 typically corresponding to the third tab in the tab navigation.
   */
  const handleButtonClick = (value) => {
    setSelectedTab(value); // Index 2 corresponds to the third tab
  };

  /* The `useEffect(() => { getCoupon() }, [])` hook in the provided code snippet is used to fetch
  coupons when the `Coupon` component mounts for the first time. */
  useEffect(() => {
    getCoupon()
  }, [])
  /* The line `const role = localStorage.getItem('role');` is retrieving the value associated with the
  key 'role' from the browser's localStorage. This value is typically set using
  `localStorage.setItem('role', 'someRoleValue')` in another part of the application or by the user. */
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  /* The `useEffect(() => { if (role !== "admin") navigate('/dashboard'); }, [role]);` code snippet is
  utilizing the `useEffect` hook in React to monitor changes in the `role` variable. */
  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  /* The `useEffect(() => { ... }, [couponId])` hook in the provided code snippet is monitoring changes
  to the `couponId` variable. When the `couponId` value changes, the code inside the `useEffect` hook
  is executed. */
  useEffect(() => {
    if (couponId) {
      console.log('couponId', couponId)
    }
  }, [couponId])
  return (
    <HStack w={'100%'} minH={'100vh'} alignItems={'flex-start'} px={8}>
      <VStack w={'30%'}>
        {
          coupons && coupons.length > 0 ? coupons.map((el, i) => (
            <>
              <Box bg={el._id === couponId && 'blue.100'} p={1} w={'100%'}>
                <HStack
                  justifyContent={'space-between'}
                  w={'95%'}
                  cursor={'pointer'}
                  key={i}
                  onClick={() => {
                    setCouponId(el._id);
                    getAboutCoupon(el.code)

                  }}
                >
                  <HStack onClick={() => handleButtonClick(0)} w={'100%'}>
                    <Text >{i + 1}.</Text>
                    <Text >{el.code}</Text>
                  </HStack>
                  <IconButton
                    icon={<FiEdit2 />}
                    aria-label="Edit"
                    colorScheme="gray"
                    ml={2}
                    onClick={() => handleButtonClick(2)}
                  />
                  <DeleteCoupon couponId={el._id}>
                    <IconButton
                      icon={<DeleteIcon />}
                      aria-label="Delete"
                      colorScheme="red"
                      ml={2}
                    />
                  </DeleteCoupon>
                </HStack>
              </Box>

            </>

          )
          ) : (
            <Text>no coupon </Text>
          )
        }
      </VStack>
      <Divider orientation="vertical" />
      <VStack w={'70%'}>
        <Tabs index={selectedTab} onChange={index => setSelectedTab(index)}>
          <TabList>
            <Tab>About Coupon</Tab>
            <Tab>Add Coupon</Tab>
            <Tab>Update Coupon</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <AboutCoupon />
            </TabPanel>
            <TabPanel>
              <AddCoupon />
            </TabPanel>
            <TabPanel>
              <UpdateCoupon />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </HStack>
  );
}

export default Coupon;
