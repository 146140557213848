import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import EnvContext from '../../../context/EnvContext';
import {
  HStack,
  Box,
  Image,
  VStack,
  UnorderedList,
  ListItem,
  OrderedList,
  Divider,
  Text,
  IconButton,
  Button,
} from '@chakra-ui/react';
import CourseContext from '../../../context/CourseListContext';
import { DeleteIcon } from '@chakra-ui/icons';
import toast from 'react-hot-toast';
import { DeleteCourse } from '../../../components/alert/DeleteCourse';
import { Link } from 'react-router-dom';
import ProjectContext from '../../../context/ProjectContext';
import { LeaderBoardOpener } from '../../../components/modals/LeaderBoardOpener';

const CourseShowcase = ({ index }) => {
  /* These lines of code are using the `useContext` hook in React to access the values stored in the
 context objects `EnvContext`, `CourseContext`, and `ProjectContext`. */
  const { backendUrl } = useContext(EnvContext);
  const { courses, fetchData, setToken, courseId } = useContext(CourseContext);
  const { getProjectSubmission } = useContext(ProjectContext);

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let loadingToast;
  /* The `useEffect` hooks in the provided code snippet are used for performing side effects in
 functional components in React. Here's what each `useEffect` is doing: */
  useEffect(() => {
    if (token) setToken(token);
  }, [token]);

  useEffect(() => {
    console.log('index', index);
  }, [index]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* This block of code is a conditional rendering in React. Here's what it's doing: */}
      {index !== null ? (
        /* The code block you provided is a conditional rendering in React. It checks if `courseId` is
      truthy and if it is, it renders a series of components and elements related to displaying
      course information. Here's a breakdown of what the code block is doing: */
        courseId && (
          <HStack spacing="14" w={'100%'}>
            <VStack>
              <HStack width={'100%'} gap={'1rem'}>
                <VStack
                  spacing={2}
                  width={'100%'}
                  gap={'1rem'}
                  alignItems={'flex-start'}
                >
                  <Button colorScheme="orange">
                    <Link to="/admin/update_course">Update Course</Link>
                  </Button>
                  <Button colorScheme="orange">
                    <Link to="/admin/manage_syllabus">Manage Syllabus</Link>
                  </Button>
                </VStack>
                <VStack
                  spacing={2}
                  width={'100%'}
                  gap={'1rem'}
                  alignItems={'flex-start'}
                >
                  <Button
                    colorScheme="orange"
                    onClick={() => {
                      localStorage.removeItem('UserId');
                      localStorage.removeItem('ProjectId');
                      localStorage.setItem('history', '/admin/courses');
                      localStorage.setItem('CourseID', courseId);
                      getProjectSubmission(undefined, courseId, undefined);
                    }}
                  >
                    Project Submissions
                  </Button>
                  <Button colorScheme="orange">
                    <Link to="/admin/manage_reviews"> Edit Reviews</Link>
                  </Button>
                </VStack>
              </HStack>

              <HStack width={'100%'} gap={'1rem'}>
                <VStack
                  spacing={2}
                  width={'100%'}
                  gap={'1rem'}
                  alignItems={'flex-start'}
                >
                  <LeaderBoardOpener>
                    <Button colorScheme="orange">User Leaderboard</Button>
                  </LeaderBoardOpener>
                </VStack>
              </HStack>

              <UnorderedList>
                <ListItem> Course Name: {courses[index]?.courseName}</ListItem>
                <ListItem>
                  Certification:
                  {courses[index]?.certification ? 'Provided' : 'Not Provided'}
                </ListItem>
                <ListItem> Language: {courses[index]?.language}</ListItem>
                <ListItem>
                  {' '}
                  No.Of raviews: {courses[index]?.noOfReviews}
                </ListItem>
                <ListItem> Offer Price: {courses[index]?.offerPrice}</ListItem>
                <ListItem>
                  {' '}
                  Original Price: {courses[index]?.originalPrice}
                </ListItem>
                <ListItem>
                  {' '}
                  Total Rating: {courses[index]?.totalRating}
                </ListItem>
                <ListItem>
                  <Image
                    h={'30vh'}
                    src={`${backendUrl}${courses[index]?.banner}`}
                  ></Image>
                </ListItem>
              </UnorderedList>
            </VStack>
          </HStack>
        )
      ) : (
        <Text fontSize={'xx-large'} fontWeight={700}>
          Selete any course{' '}
        </Text>
      )}
    </>
  );
};

export default CourseShowcase;
