import React, { useContext, useEffect, useRef, useState } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  HStack,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { GrAdd } from 'react-icons/gr';
import { AdminPreviewAnswer } from '../drawer/AdminPreviewAnswer';
import AssignmentContext from '../../context/AssignmentContext';

const AddQuestionForm = () => {
  const { answers, setAnswers, addQnA } = useContext(AssignmentContext);
  const [questionImage, setQuestionImage] = useState([]);
  const [textOptions, setTextOptions] = useState([]);
  const [filteredTO, setFilteredTO] = useState([]);
  const [optionImages, setOptionImages] = useState([]);

  const initialValues = {
    questionText: '',
    options: [{ type: 'text', value: '' }],
    description: '',
    noOfAttempts: 0,
  };
  const handleAnswerChange = (index, newValue) => {
    const updatedAnswers = [...textOptions];
    updatedAnswers[index] = newValue;
    setTextOptions(updatedAnswers);
    filterTextAnswers();
  };

  useEffect(() => {
    filterTextAnswers();
  }, [textOptions]);
  const filterTextAnswers = () => {
    const updatedAnswers = [...textOptions];
    setFilteredTO(updatedAnswers.filter(value => value !== undefined));
  };

  // const handleImageChange = (index, newValue) => {
  //   const updatedAnswers = [...optionImages];
  //   updatedAnswers[index] = newValue;
  //   setOptionImages(updatedAnswers.filter(value => value !== undefined));
  // };
  const handleImageChange = event => {
    const files = event.target.files;
    const renamedFiles = Array.from(files).map((file, index) => {
      const newName = `Option${index + 1}`;
      const fileType = file.type;
      return new File([file], `${newName}.${fileType.split('/')[1]}`, {
        type: fileType,
      });
    });
    setOptionImages(renamedFiles);
  };
  const fileInputRef = useRef(null);
  const handleSubmit = async (values, actions) => {
    console.log({ ...values, optionImages, filteredTO, answers });
    // Here you can make your API call using the values object
    const formData = new FormData();
    formData.append('questionText', values.questionText);
    formData.append('questionImage', questionImage);
    answers?.forEach((value, index) => {
      formData.append(`answer[${index}]`, value);
    });

    formData.append('description', values.description);
    filteredTO.forEach((value, index) => {
      formData.append(`textOptions[${index}]`, value);
    });
    optionImages.forEach(image => formData.append('optionImages', image));

    formData.append('noOfAttempts', values.noOfAttempts);

    await addQnA(formData);
    setQuestionImage([]);
    setTextOptions([]);
    setFilteredTO([]);
    setOptionImages([]);
    setAnswers([]);
    // Reset Formik form
    console.log('Resetting form...');
    actions.resetForm({ values: initialValues });
    console.log('Form reset to:', initialValues);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    actions.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <Field name="questionText">
            {({ field, form }) => (
              <FormControl
                isInvalid={
                  form.errors.questionText && form.touched.questionText
                }
              >
                <FormLabel>Text Question</FormLabel>
                <Input
                  {...field}
                  type="text"
                  placeholder="Enter the question"
                />
                <FormErrorMessage>{form.errors.questionText}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <FormControl>
            <FormLabel>Question Image</FormLabel>
            <Input
              name="questionImage"
              type="file"
              onChange={e => setQuestionImage(e.target.files[0])}
            />
          </FormControl>

          <Field name="description">
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.description && form.touched.description}
              >
                <FormLabel>Question Description</FormLabel>
                <Input
                  {...field}
                  type="text"
                  placeholder="Add description to the question"
                />
                <FormErrorMessage>{form.errors.description}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="noOfAttempts">
            {({ field, form }) => (
              <FormControl
                isInvalid={
                  form.errors.noOfAttempts && form.touched.noOfAttempts
                }
              >
                <FormLabel>No Of Attempts</FormLabel>
                <Input
                  {...field}
                  type="number"
                  placeholder="No of attempts for user"
                />
                <FormErrorMessage>{form.errors.noOfAttempts}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <FieldArray name="options">
            {({ push, remove }) => (
              <VStack spacing={4} width={'100%'}>
                {values.options.map((_, index) => (
                  <HStack key={index} spacing={4} width={'100%'}>
                    <FormControl>
                      <FormLabel>Text Option</FormLabel>
                      <HStack spacing={2}>
                        <Field name={`options.${index}.value`}>
                          {({ field, form }) => (
                            <Input
                              {...field}
                              placeholder="Enter option text"
                              onChange={e => {
                                // Update Formik state
                                form.setFieldValue(field.name, e.target.value);
                                // Custom handler
                                handleAnswerChange(index, e.target.value);
                              }}
                            />
                          )}
                        </Field>
                        <IconButton
                          icon={<DeleteIcon />}
                          onClick={() => remove(index)}
                          aria-label="Delete"
                          colorScheme="red"
                          ml={2}
                        />
                        <IconButton
                          icon={<GrAdd />}
                          onClick={() => push({ type: 'text', value: '' })}
                          aria-label="Add"
                          colorScheme="green"
                          ml={2}
                        />
                      </HStack>
                    </FormControl>
                  </HStack>
                ))}
                {values.options.length === 0 && (
                  <Button
                    colorScheme="green"
                    onClick={() => push({ type: 'text', value: '' })}
                  >
                    Add Text Answer
                  </Button>
                )}
              </VStack>
            )}
          </FieldArray>

          <FormControl>
            <FormLabel>Image Option</FormLabel>
            <HStack spacing={2}>
              <Input
                multiple
                type="file"
                ref={fileInputRef}
                onChange={e => handleImageChange(e)}
              />
            </HStack>
          </FormControl>
          <HStack>
            <AdminPreviewAnswer
              optionImages={optionImages}
              filteredTO={filteredTO}
            >
              <Button mt={4} colorScheme="orange" type="button">
                Preview Option
              </Button>
            </AdminPreviewAnswer>

            <Button mt={4} colorScheme="blue" type="submit">
              Submit
            </Button>
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default AddQuestionForm;
