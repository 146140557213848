import React from 'react';
import AssignmentContext from '../../../context/AssignmentContext';
import {
  Box,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  Text,
  IconButton,
  Divider,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { DeleteContent } from '../../../components/alert/DeleteContent';
import { DeleteQue } from '../../../components/alert/DeleteQue';
export const AllAssignments = () => {
  /* This line of code is using the `useContext` hook from React to access the context values provided
  by the `AssignmentContext`. It is destructuring the values `assignments`, `setQuesId`, `quesId`,
  and `getQnA` from the context object returned by `React.useContext(AssignmentContext)`. */
  const { assignments, setQuesId, quesId, getQnA } = React.useContext(AssignmentContext);
  console.log(assignments)
  return (

    <>
      {assignments && assignments.length > 0 ? (
        assignments.map((el, i) => (
          <>
            <Box bg={el._id === quesId && 'blue.100'} p={1} w={'100%'} key={el._id}>
              <HStack
                key={i}
                onClick={() => {
                  setQuesId(el._id);
                  getQnA(el._id);
                }}
                cursor={'pointer'}
                spacing={3}
                w={'100%'}
                justifyContent={'space-between'}
              >
                <HStack spacing={2}>
                  <Text fontWeight={500}>{i + 1})</Text>
                  <Text>{el.question.text}</Text>
                </HStack>

                <DeleteQue queId={el._id}>
                  <IconButton
                    icon={<DeleteIcon />}
                    aria-label="Delete"
                    colorScheme="red"
                    ml={2}
                  />
                </DeleteQue>
              </HStack>
            </Box>

            {i !== assignments.length - 1 && (
              <Divider orientation="horizontal" />
            )}
          </>
        )
        )
      ) : (
        <p>Nothing to show here. No assignments available</p>
      )}
    </>
  );
};
