import React, { useContext, useEffect, useState, useRef } from 'react';
import { Formik, Form, Field, FieldArray, useFormikContext } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  Checkbox,
  FormErrorMessage,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  Textarea,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Spacer,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { HiEye, HiEyeOff } from 'react-icons/hi'; // Assuming you have imported these icons
import { AddSyllabus } from '../../../components/modals/AddSyllabus';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { AddMentor } from '../../../components/modals/AddMentor';
import { MentorList } from '../../../components/modals/MentorList';
import SyllabusContext from '../../../context/SyllabusContext';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';
import MentorContext from '../../../context/MentorContext';
import { useNavigate } from 'react-router-dom';

/**
 * The DisplayFormValues component renders a form field for selecting a syllabus section, displaying
 * the selected syllabus ID if available or providing an option to add a syllabus.
 * @returns The `DisplayFormValues` component is returning JSX elements that include a form for
 * displaying syllabus information. The form includes a section for the course name, a box with
 * styling, and a form control for the syllabus section. Depending on whether a `syllabusId` is
 * provided, it will either display the selected syllabus information with the reference ID or show a
 * button to add a syll
 */
const DisplayFormValues = ({ syllabusId }) => {
  const { values } = useFormikContext();
  return (
    <AddSyllabus courseName={values.courseName}>
      <Box p={6} boxShadow={'lg'} rounded={'md'} width={'25rem'}>
        <FormControl>
          <FormLabel>Syllabus Section</FormLabel>
          {syllabusId ? (
            <>
              <Text>
                Syllabus is <strong>selected</strong>. Ref Id:{' '}
                <strong>{syllabusId}</strong>{' '}
              </Text>
            </>
          ) : (
            <Button
              leftIcon={<MdOutlineLibraryAdd />}
              colorScheme="blue"
              variant="outline"
            >
              Add Syllabus
            </Button>
          )}
        </FormControl>
      </Box>
    </AddSyllabus>
  );
};

/* The above JavaScript code defines an array named `categories` that contains objects representing
different categories such as Programming, Design, Business, Science, Arts, Personal Development,
Health & Fitness, and Language Learning. Each category object has a `label` property indicating the
category name and a `subCategories` property which is an array of sub-categories related to the main
category. For example, the 'Programming' category has sub-categories like 'Programming languages',
'Web Development', 'Mobile Development', and others. This data structure can be used to organize and
categorize information in a hierarchical manner. */
const categories = [
  {
    label: 'Programming',
    subCategories: [
      'Programming languages',
      'Web Development',
      'Mobile Development',
      'Game Development',
      'Data Science',
      'System Programming',
    ],
  },
  {
    label: 'Design',
    subCategories: ['Graphic Design', 'UI/UX Design', 'Web Design'],
  },
  {
    label: 'Business',
    subCategories: ['Management', 'Finance', 'Marketing'],
  },
  {
    label: 'Science',
    subCategories: ['Biology', 'Physics', 'Chemistry'],
  },
  {
    label: 'Arts',
    subCategories: ['Music', 'Photography', 'Writing'],
  },
  {
    label: 'Personal Development',
    subCategories: ['Leadership', 'Productivity', 'Career Development'],
  },
  {
    label: 'Health & Fitness',
    subCategories: ['Fitness', 'Nutrition', 'Mental Health'],
  },
  {
    label: 'Language Learning',
    subCategories: ['English', 'Spanish', 'Mandarin Chinese'],
  },
];

const CourseForm = () => {
  const toast = useToast();
  const { backendUrl } = useContext(EnvContext);
  const { mentorId, mentorName } = useContext(MentorContext);
  const { syllabusId } = useContext(SyllabusContext);
  /* The above code is written in JavaScript and it defines several state variables using the useState
 hook from React. Here is a breakdown of what each state variable represents: */
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoFile, setVideoFile] = useState({});
  const [bannerFile, setBannerFile] = useState({});
  const [logoFile, setLogoFile] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMentor, setSelectedMentor] = useState('');

  /* The above code snippet is written in JavaScript and it appears to be using React's useState hook
  to initialize a state variable called `initialValues`. The `initialValues` state object contains
  various properties related to a course, such as course name, language, category, subcategory,
  pricing details, duration, certification, level, facilities, and requirements. The initial values
  for these properties are set to empty strings, an empty array for course facilities and
  requirements, and a boolean value for certification. */
  const [initialValues, setInitialValues] = useState({
    courseName: '',
    language: '',
    category: '',
    subCategory: '',
    advQuote: '',
    courseDurationHour: '',
    courseClassInterval: '',
    originalPrice: '',
    offerPrice: '',
    certification: true,
    courseLevel: '',
    courseFacilities: [''],
    courseRequirements: [''],
  });

  /* The above code is retrieving a token from the browser's localStorage using the key 'token' and then
creating a headers object with an Authorization property that includes the token in a Bearer token
format. This is commonly used for making authenticated API requests by including the token in the
request headers. */
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  let loadingToast;
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  const [showBannerUpload, setShowBannerUpload] = useState(false);
  const [showLogoUpload, setShowLogoUpload] = useState(false);

  useEffect(() => {
    if (role !== 'admin') navigate('/dashboard');
  }, [role]);

  /**
   * The function `handleSubmit` handles form submission by sending form data to a backend server and
   * displaying progress and success/error messages using Axios and Toast notifications.
   * @param values - The `values` parameter in the `handleSubmit` function likely contains form data
   * such as `courseName`, `language`, `category`, `subCategory`, `advQuote`, `courseDurationHour`,
   * `courseClassInterval`, `originalPrice`, `offerPrice`, `certification`, `courseLevel
   * @param actions - The `actions` parameter in the `handleSubmit` function likely refers to a set of
   * functions or methods provided by a form library like Formik. These actions can be used to interact
   * with the form state and trigger certain behaviors, such as setting form field values, handling
   * submission events, and managing form submission
   */
  const handleSubmit = async (values, actions) => {
    // Handle form submission here
    console.log('initial value', values);
    console.log('mentorId', mentorId);
    console.log('syllabusId', syllabusId);
    console.log('videoFile', videoFile);
    console.log('bannerFile', bannerFile);
    console.log('logoFile', logoFile);

    /* The above code is creating a new FormData object, which is commonly used in JavaScript to gather and
send form data in an HTML document. */
    const formData = new FormData();
    formData.append('banner', bannerFile);
    formData.append('logo', logoFile);
    formData.append('advVideo', videoFile);
    formData.append('courseName', values.courseName);
    formData.append('language', values.language);
    formData.append('mentor', mentorId);
    formData.append('syllabus', syllabusId);
    formData.append('category', values.category);
    formData.append('subCategory', values.subCategory);
    formData.append('advQuote', values.advQuote);
    formData.append('courseDurationHour', values.courseDurationHour);
    formData.append('courseClassInterval', values.courseClassInterval);
    formData.append('originalPrice', values.originalPrice);
    formData.append('offerPrice', values.offerPrice);
    formData.append('certification', values.certification);
    formData.append('courseLevel', values.courseLevel);
    values.courseFacilities.forEach((value, index) => {
      formData.append(`courseFacilities[${index}]`, value);
    });
    values.courseRequirements.forEach((value, index) => {
      formData.append(`courseRequirements[${index}]`, value);
    });
    const addCoursePromise = axios.post(`${backendUrl}/course`, formData, {
      headers: headers,
      onUploadProgress: progressEvent => {
        // Calculate the upload percentage
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setUploadProgress(percentage);
      },
    });
    toast.promise(addCoursePromise, {
      loading: {
        title: 'Course is adding up ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Course added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add course!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });

    try {
      const res = await addCoursePromise;
      console.log(res);
    } catch (err) {
      console.error(err);
    }

    actions.setSubmitting(false);
  };
  return (
    <Box width={'95%'} px={'5rem'}>
      {/* /* The above code is a form component written in JavaScript using the Formik library for handling
      form state and validation. It includes various form fields for creating a course, such as
      course name, level, language, category, subcategory, course facilities, certification
      checkbox, duration, description, prices, advertisement quote, video upload, requirements, and
      mentor selection. */}
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, ...formikProps }) => (
          <Form>
            <VStack width={'100%'}>
              <HStack spacing={5} width={'100%'} alignItems={'flex-start'}>
                <VStack spacing="6" width={'50%'}>
                  {/* Course Name */}
                  <Field name="courseName">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseName && form.touched.courseName
                        }
                      >
                        <FormLabel>Course Name</FormLabel>
                        <Input {...field} placeholder="Enter course name" />
                        <FormErrorMessage>
                          {form.errors.courseName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* Course Level */}
                  <Field name="courseLevel">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseLevel && form.touched.courseLevel
                        }
                      >
                        <FormLabel>Course Level</FormLabel>
                        <Select
                          {...field}
                          placeholder="Select course level"
                          onChange={e =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        >
                          {/* Add course level options here */}
                          <option value="beginner">Beginner</option>
                          <option value="intermediate">Intermediate</option>
                          <option value="advanced">Advanced</option>
                        </Select>
                        <FormErrorMessage>
                          {form.errors.courseLevel}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* Language */}
                  <Field name="language">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.language && form.touched.language
                        }
                      >
                        <FormLabel>Language</FormLabel>
                        <Input {...field} placeholder="Enter language name" />
                        <FormErrorMessage>
                          {form.errors.language}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/*sub Category */}
                  <FormControl>
                    <FormLabel>Category</FormLabel>
                    <Select
                      placeholder="Select category"
                      onChange={e => {
                        setSelectedCategory(e.target.value);
                        formikProps.setFieldValue('category', e.target.value);
                      }}
                    >
                      {categories.map((category, index) => (
                        <option key={index} value={category.label}>
                          {category.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  {selectedCategory && (
                    <FormControl>
                      <FormLabel>Sub Category</FormLabel>
                      <Select
                        placeholder="Select sub category"
                        onChange={e => {
                          formikProps.setFieldValue(
                            'subCategory',
                            e.target.value
                          );
                        }}
                      >
                        {categories
                          .find(category => category.label === selectedCategory)
                          .subCategories.map((subCategory, index) => (
                            <option key={index} value={subCategory}>
                              {subCategory}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                  {/* upload photo   */}

                  <FormControl>
                    <HStack>
                      <FormLabel>Upload Banner</FormLabel>

                      {showBannerUpload ? (
                        <input
                          id="fileInput"
                          type="file"
                          accept="image/jpeg, image/png, image/gif" // Only allow specified image file types
                          onChange={e => setBannerFile(e.target.files[0])}
                        />
                      ) : (
                        <a
                          href="https://imagecropper.stepsoflearningprocess.com/crop5to3"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setShowBannerUpload(true)}
                        >
                          First verify the image here :{' '}
                          <span style={{ color: 'blue' }}>Image Verifier</span>
                        </a>
                      )}
                    </HStack>
                  </FormControl>

                  <FormControl>
                    <HStack>
                      <FormLabel>Upload Logo</FormLabel>
                      {showLogoUpload ? (
                        <input
                          id="fileInputs"
                          type="file"
                          accept="image/jpeg, image/png, image/svg+xml, image/x-icon"
                          onChange={e => setLogoFile(e.target.files[0])}
                        />
                      ) : (
                        <a
                          href="https://imagecropper.stepsoflearningprocess.com/crop1to1"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => setShowLogoUpload(true)}
                        >
                          First verify the image here :{' '}
                          <span style={{ color: 'blue' }}>Logo Verifier</span>
                        </a>
                      )}
                    </HStack>
                  </FormControl>

                  {/* course Facilities */}
                  <VStack w={'100%'}>
                    <FormLabel bg={'cyan.100'} p={2} borderRadius={10}>
                      Add Course Facilities
                    </FormLabel>
                    <FieldArray name="courseFacilities">
                      {({ push, remove, form }) => (
                        <>
                          {formikProps.values.courseFacilities.length > 0 ? (
                            formikProps.values.courseFacilities.map(
                              (facility, index) => (
                                <FormControl
                                  key={index}
                                  isInvalid={
                                    form.errors.courseFacilities &&
                                    form.touched.courseFacilities
                                  }
                                >
                                  <FormLabel>
                                    Course Facility {index + 1}
                                  </FormLabel>
                                  <HStack>
                                    <Input
                                      value={facility}
                                      onChange={e =>
                                        formikProps.setFieldValue(
                                          `courseFacilities.${index}`,
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter course facility"
                                    />
                                    <IconButton
                                      icon={<DeleteIcon />}
                                      aria-label="Delete"
                                      onClick={() => remove(index)}
                                      colorScheme="red"
                                      ml={2}
                                    />
                                    <IconButton
                                      leftIcon={<AddIcon />}
                                      aria-label="Add"
                                      onClick={() => push('')}
                                      ml={2}
                                    />
                                  </HStack>

                                  <FormErrorMessage>
                                    {form.errors.courseFacilities &&
                                      form.errors.courseFacilities[index]}
                                  </FormErrorMessage>
                                </FormControl>
                              )
                            )
                          ) : (
                            <Button mt={4} onClick={() => push('')}>
                              Add Another Facility
                            </Button>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </VStack>

                  {/* Certification Checkbox */}
                  <Field name="certification" type="checkbox">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.certification &&
                          form.touched.certification
                        }
                      >
                        <Checkbox {...field} defaultChecked={true}>
                          Do you Want to certify this course?
                        </Checkbox>
                        <FormErrorMessage>
                          {form.errors.certification}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <DisplayFormValues syllabusId={syllabusId} />
                </VStack>

                <VStack spacing="6" width={'50%'}>
                  {/* Course Duration (Hour) */}
                  <Field name="courseDurationHour">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseDurationHour &&
                          form.touched.courseDurationHour
                        }
                      >
                        <FormLabel>Course Duration (Hour)</FormLabel>
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter duration in hours"
                        />
                        <FormErrorMessage>
                          {form.errors.courseDurationHour}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Course Class Interval */}
                  <Field name="courseClassInterval">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseClassInterval &&
                          form.touched.courseClassInterval
                        }
                      >
                        <FormLabel>Course Description</FormLabel>
                        <Textarea
                          {...field}
                          type="text"
                          placeholder="Enter Course Description"
                        />
                        <FormErrorMessage>
                          {form.errors.courseClassInterval}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Original Price */}
                  <Field name="originalPrice">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.originalPrice &&
                          form.touched.originalPrice
                        }
                      >
                        <FormLabel>Original Price</FormLabel>
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter original price"
                        />
                        <FormErrorMessage>
                          {form.errors.originalPrice}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Offer Price */}
                  <Field name="offerPrice">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.offerPrice && form.touched.offerPrice
                        }
                      >
                        <FormLabel>Offer Price</FormLabel>
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter offer price"
                        />
                        <FormErrorMessage>
                          {form.errors.offerPrice}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Adv Quote */}
                  <Field name="advQuote">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.advQuote && form.touched.advQuote
                        }
                      >
                        <FormLabel>Adv Quote</FormLabel>
                        <Input
                          {...field}
                          placeholder="Enter advertisement quote"
                        />
                        <FormErrorMessage>
                          {form.errors.advQuote}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* upload video   */}
                  <HStack justifyContent={'space-evenly'} width={'100%'}>
                    <FormControl>
                      <HStack>
                        <FormLabel>Upload Video</FormLabel>

                        <input
                          id="videoUpload"
                          name="video"
                          type="file"
                          accept="video/mp4, video/avi, video/mov"
                          onChange={e => setVideoFile(e.target.files[0])}
                        />
                      </HStack>
                    </FormControl>

                    <CircularProgress value={uploadProgress} color="green.400">
                      <CircularProgressLabel>{`${Math.round(
                        uploadProgress
                      )}%`}</CircularProgressLabel>
                    </CircularProgress>
                  </HStack>

                  {/* course Requirements */}
                  <VStack w={'100%'}>
                    <FormLabel bg={'cyan.100'} p={2} borderRadius={10}>
                      Add Course Requirements
                    </FormLabel>
                    <FieldArray name="courseRequirements">
                      {({ push, remove, form }) => (
                        <>
                          {formikProps.values.courseRequirements.length > 0 ? (
                            formikProps.values.courseRequirements.map(
                              (facility, index) => (
                                <FormControl
                                  key={index}
                                  isInvalid={
                                    form.errors.courseRequirements &&
                                    form.touched.courseRequirements
                                  }
                                >
                                  <FormLabel>
                                    Course Requirements {index + 1}
                                  </FormLabel>
                                  <HStack>
                                    <Input
                                      value={facility}
                                      onChange={e =>
                                        formikProps.setFieldValue(
                                          `courseRequirements.${index}`,
                                          e.target.value
                                        )
                                      }
                                      placeholder="Enter course requirements"
                                    />
                                    <IconButton
                                      icon={<DeleteIcon />}
                                      aria-label="Delete"
                                      onClick={() => remove(index)}
                                      colorScheme="red"
                                      ml={2}
                                    />
                                    <IconButton
                                      leftIcon={<AddIcon />}
                                      aria-label="Add"
                                      onClick={() => push('')}
                                      ml={2}
                                    />
                                  </HStack>

                                  <FormErrorMessage>
                                    {form.errors.courseRequirements &&
                                      form.errors.courseRequirements[index]}
                                  </FormErrorMessage>
                                </FormControl>
                              )
                            )
                          ) : (
                            <Button mt={4} onClick={() => push('')}>
                              Add Requirement
                            </Button>
                          )}
                        </>
                      )}
                    </FieldArray>
                  </VStack>

                  {/* mentor handling  */}
                  <MentorList setSelectedMentor={setSelectedMentor}>
                    <Box p={6} boxShadow={'lg'} rounded={'md'} width={'25rem'}>
                      <FormControl>
                        <FormLabel>Mentor Section</FormLabel>

                        {selectedMentor ? (
                          <>
                            <Text>
                              <strong>Selected Mentor :</strong>
                              {selectedMentor}{' '}
                            </Text>{' '}
                            <Button
                              leftIcon={<MdOutlineLibraryAdd />}
                              colorScheme="blue"
                              variant="outline"
                            >
                              Change Mentor
                            </Button>
                          </>
                        ) : (
                          <Button
                            leftIcon={<MdOutlineLibraryAdd />}
                            colorScheme="blue"
                            variant="outline"
                          >
                            Add Mentor
                          </Button>
                        )}
                      </FormControl>
                    </Box>
                  </MentorList>
                </VStack>
              </HStack>

              <Button
                type="submit"
                isLoading={isSubmitting}
                width={'40%'}
                mt={5}
              >
                Submit
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
      <Toaster />
    </Box>
  );
};

export default CourseForm;
