import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field, FieldArray, useFormikContext } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  Checkbox,
  FormErrorMessage,
  IconButton,
  InputGroup,
  InputRightElement,
  HStack,
  Textarea,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { HiEye, HiEyeOff } from 'react-icons/hi'; // Assuming you have imported these icons
import { AddSyllabus } from '../../../components/modals/AddSyllabus';
import { MdOutlineLibraryAdd } from 'react-icons/md';
import { AddMentor } from '../../../components/modals/AddMentor';
import { MentorList } from '../../../components/modals/MentorList';
import SyllabusContext from '../../../context/SyllabusContext';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';
import MentorContext from '../../../context/MentorContext';
import CourseContext from '../../../context/CourseListContext';
import { CloseIcon } from '@chakra-ui/icons';
import { UpdateSyllabusModal } from '../../../components/modals/UpdateSyllabusModal';
import { useNavigate } from 'react-router-dom';
/**
 * The DisplayFormValues function renders a form component with a button to update the syllabus
 * section.
 * @returns The `DisplayFormValues` component is returning JSX elements that include an
 * `UpdateSyllabusModal` component with a `courseName` prop set to `values.courseName`, a `Box`
 * component with padding, box shadow, rounded corners, and width styling, and a `FormControl`
 * component containing a `FormLabel` and a `Button` with an icon and text.
 */
const DisplayFormValues = () => {
  const { values } = useFormikContext();

  return (
    <UpdateSyllabusModal courseName={values.courseName}>
      <Box p={6} boxShadow={'lg'} rounded={'md'} width={'25rem'}>
        <FormControl>
          <FormLabel>Syllabus Section</FormLabel>

          <Button
            leftIcon={<MdOutlineLibraryAdd />}
            colorScheme="blue"
            variant="outline"
          >
            Update Syllabus
          </Button>
        </FormControl>
      </Box>
    </UpdateSyllabusModal>
  );
};

/* The above JavaScript code defines an array of objects representing different categories. Each
category object contains a label representing the category name and an array of subCategories
representing specific subcategories within that category. The categories include Programming,
Design, Business, Science, Arts, Personal Development, Health & Fitness, and Language Learning, each
with their respective subcategories. */
const categories = [
  {
    label: 'Programming',
    subCategories: [
      'Web Development',
      'Mobile Development',
      'Game Development',
      'Data Science',
      'System Programming',
    ],
  },
  {
    label: 'Design',
    subCategories: ['Graphic Design', 'UI/UX Design', 'Web Design'],
  },
  {
    label: 'Business',
    subCategories: ['Management', 'Finance', 'Marketing'],
  },
  {
    label: 'Science',
    subCategories: ['Biology', 'Physics', 'Chemistry'],
  },
  {
    label: 'Arts',
    subCategories: ['Music', 'Photography', 'Writing'],
  },
  {
    label: 'Personal Development',
    subCategories: ['Leadership', 'Productivity', 'Career Development'],
  },
  {
    label: 'Health & Fitness',
    subCategories: ['Fitness', 'Nutrition', 'Mental Health'],
  },
  {
    label: 'Language Learning',
    subCategories: ['English', 'Spanish', 'Mandarin Chinese'],
  },
];
const UpdateCourse = () => {
  /* The above code is written in JavaScript and it seems to be using a function or method called
 `useToast()` to display a toast message. The `toast` variable is assigned the result of calling
 `useToast()`, which is likely a function provided by a library or framework for showing toast
 notifications. However, the specific implementation details and functionality of `useToast()` would
 depend on the context in which this code is being used. */
  const toast = useToast();
  /* The above code snippet is written in JavaScript and is using the useContext hook from React to
 access context values in a functional component. It is extracting specific values from different
 contexts such as CourseContext, EnvContext, MentorContext, and SyllabusContext using object
 destructuring. */
  const { courseData, courseId } = useContext(CourseContext);
  const { backendUrl } = useContext(EnvContext);
  const { mentorId, setMentorId } = useContext(MentorContext);
  const { syllabusId, setSyllabusId } = useContext(SyllabusContext);
  /* The above code is written in JavaScript and it defines multiple state variables using the useState
hook from React. Here is a summary of what each state variable is used for: */
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoFile, setVideoFile] = useState({});
  const [bannerFile, setBannerFile] = useState({});
  const [logoFile, setLogoFile] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newItem, setNewItem] = useState('');
  const [newItemRequirement, setNewItemRequirement] = useState('');
  const [courseFacilities, setcourseFacilities] = useState([]);
  const [courseRequirements, setcourseRequirements] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState('');
  /* The above code is written in JavaScript and it performs the following tasks: */
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') navigate('/dashboard');
  }, [role]);

  /* The above code snippet is written in JavaScript and it defines a state variable `initialValues`
using the `useState` hook from React. The `initialValues` object contains various properties related
to a course such as course name, language, category, subcategory, advanced quote, duration, price
details, certification status, level, facilities, and requirements. The initial values for these
properties are set to empty strings, false, or empty arrays as appropriate. This state variable can
be used to manage form data or course details in a React component. */
  const [initialValues, setInitialValues] = useState({
    courseName: '',
    language: '',
    category: '',
    subCategory: '',
    advQuote: '',
    courseDurationHour: '',
    courseClassInterval: '',
    originalPrice: '',
    offerPrice: '',
    certification: false,
    courseLevel: '',
    courseFacilities: [''],
    courseRequirements: [''],
  });

  /**
   * The `addItem` function adds a new item to a list of course facilities if the new item is not empty.
   */
  const addItem = () => {
    if (newItem.trim() !== '') {
      setcourseFacilities([...courseFacilities, newItem]);
      setNewItem('');
    }
  };
  /**
   * The function `removeItem` removes an item from an array based on its index.
   */
  const removeItem = index => {
    const updatedItems = courseFacilities.filter((_, i) => i !== index);
    setcourseFacilities(updatedItems);
  };

  /**
   * The function `removeRequirements` removes an item from an array called `courseRequirements` based on
   * the provided index.
   */
  const removeRequirements = index => {
    const updatedItems = courseRequirements.filter((_, i) => i !== index);
    setcourseRequirements(updatedItems);
  };
  /**
   * The function `addItemRequirement` adds a new item requirement to a list of course requirements.
   */
  const addItemRequirememnt = () => {
    if (newItemRequirement.trim() !== '') {
      setcourseRequirements([...courseRequirements, newItemRequirement]);
      setNewItemRequirement('');
    }
  };

  /* The above code is using the `useEffect` hook in React to log the `courseData` variable whenever it
 changes. The `useEffect` hook is triggered whenever the dependencies in the second argument array
 (in this case, `[courseData]`) change. So, whenever `courseData` changes, the console will log
 'updatecourseData' followed by the value of `courseData`. */
  useEffect(() => {
    if (courseData) {
      console.log('updatecourseData', courseData);
    }
  }, [courseData]);

  /* The above code snippet is a React useEffect hook that is triggered when the `courseData` variable
changes. It checks if `courseData` is truthy, and if so, it updates various state variables using
the data from `courseData`. */
  useEffect(() => {
    if (courseData) {
      setInitialValues({
        ...initialValues,
        courseName: courseData.courseName,
        language: courseData.language,
        subCategory: courseData.subCategory,
        advQuote: courseData.advQuote,
        courseDurationHour: courseData.courseDurationHour,
        courseClassInterval: courseData.courseClassInterval,
        originalPrice: courseData.originalPrice,
        offerPrice: courseData.offerPrice,
        courseLevel: courseData.courseLevel,
      });
      setcourseFacilities(courseData.courseFacilities);
      setcourseRequirements(courseData.courseRequirements);
      setMentorId(courseData?.mentor?._id);
      setSyllabusId(courseData?.syllabus?._id);
    }
  }, [courseData]);

  let loadingToast;

  /**
   * The function `handleSubmit` handles form submission by sending form data to update a course with
   * appropriate feedback messages.
   * @param values - The `values` parameter in the `handleSubmit` function likely contains form data
   * such as `courseName`, `language`, `category`, `subCategory`, `advQuote`, `courseDurationHour`,
   * `courseClassInterval`, `originalPrice`, `offerPrice`, `certification`, and `course
   * @param actions - The `actions` parameter in the `handleSubmit` function likely refers to a set of
   * functions or methods provided by the form library you are using (such as Formik) to interact with
   * the form state and submission process.
   */
  const handleSubmit = async (values, actions) => {
    // Handle form submission here
    console.log('initial value', values);
    console.log('mentorId', mentorId);
    console.log('syllabusId', syllabusId);
    console.log('videoFile', videoFile);
    console.log('bannerFile', bannerFile);
    console.log('logoFile', logoFile);
    console.log('courseFacilities', courseFacilities);
    console.log('courseRequirements', courseRequirements);

    const formData = new FormData();
    formData.append('banner', bannerFile);
    formData.append('logo', logoFile);
    formData.append('advVideo', videoFile);
    formData.append('courseName', values.courseName);
    formData.append('language', values.language);
    formData.append('mentor', mentorId);
    formData.append('syllabus', syllabusId);
    formData.append('category', values.category);
    formData.append('subCategory', values.subCategory);
    formData.append('advQuote', values.advQuote);
    formData.append('courseDurationHour', values.courseDurationHour);
    formData.append('courseClassInterval', values.courseClassInterval);
    formData.append('originalPrice', values.originalPrice);
    formData.append('offerPrice', values.offerPrice);
    formData.append('certification', values.certification);
    formData.append('courseLevel', values.courseLevel);
    courseFacilities.forEach((value, index) => {
      formData.append(`courseFacilities[${index}]`, value);
    });
    courseRequirements.forEach((value, index) => {
      formData.append(`courseRequirements[${index}]`, value);
    });
    const updateCoursePromise = axios.put(
      `${backendUrl}/course?courseId=${courseId}`,
      formData,
      {
        headers: headers,
      }
    );
    toast.promise(updateCoursePromise, {
      loading: {
        title: 'Course is updtaing up ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Course updated successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to update course!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });
    try {
      const res = await updateCoursePromise;
      console.log(res);
    } catch (err) {
      console.error(err);
    }
    actions.setSubmitting(false);
  };
  return (
    <Box width={'95%'} px={'5rem'}>
      {/* The above code is a form component written in JavaScript using the Formik library for handling
      form state and validation. It is a form for creating a course with various fields such as
      course name, course level, language, category, subcategory, course facilities, certification
      checkbox, course duration, original price, offer price, advertisement quote, video upload,
      course requirements, and mentor handling. */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting, ...formikProps }) => (
          <Form>
            <VStack width={'100%'}>
              <HStack spacing={5} width={'100%'} alignItems={'flex-start'}>
                <VStack spacing="6" width={'50%'}>
                  {/* Course Name */}
                  <Field name="courseName">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseName && form.touched.courseName
                        }
                      >
                        <FormLabel>Course Name</FormLabel>
                        <Input {...field} placeholder="Enter course name" />
                        <FormErrorMessage>
                          {form.errors.courseName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* Course Level */}
                  <Field name="courseLevel">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseLevel && form.touched.courseLevel
                        }
                      >
                        <FormLabel>Course Level</FormLabel>
                        <Select
                          {...field}
                          placeholder="Select course level"
                          onChange={e =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        >
                          {/* Add course level options here */}
                          <option value="beginner">Beginner</option>
                          <option value="intermediate">Intermediate</option>
                          <option value="advanced">Advanced</option>
                        </Select>
                        <FormErrorMessage>
                          {form.errors.courseLevel}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/* Language */}
                  <Field name="language">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.language && form.touched.language
                        }
                      >
                        <FormLabel>Language</FormLabel>
                        <Input {...field} placeholder="Enter language name" />
                        <FormErrorMessage>
                          {form.errors.language}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  {/*sub Category */}
                  <FormControl>
                    <FormLabel>Category</FormLabel>
                    {courseData ? (
                      <Text>
                        <strong>Previous Selected Category:</strong>
                        {courseData.category}
                      </Text>
                    ) : (
                      ''
                    )}

                    <Select
                      placeholder="Select category"
                      onChange={e => {
                        setSelectedCategory(e.target.value);
                        formikProps.setFieldValue('category', e.target.value);
                      }}
                    >
                      {categories.map((category, index) => (
                        <option key={index} value={category.label}>
                          {category.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  {selectedCategory && (
                    <FormControl>
                      <FormLabel>Sub Category</FormLabel>
                      <Text>
                        <strong>Previous Selected Sub Category:</strong>
                        {courseData.subCategory}
                      </Text>
                      <Select
                        placeholder="Select sub category"
                        onChange={e => {
                          formikProps.setFieldValue(
                            'subCategory',
                            e.target.value
                          );
                        }}
                      >
                        {categories
                          .find(category => category.label === selectedCategory)
                          .subCategories.map((subCategory, index) => (
                            <option key={index} value={subCategory}>
                              {subCategory}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                  {/* upload photo   */}

                  <FormControl>
                    <HStack>
                      <FormLabel>Upload Banner</FormLabel>

                      <input
                        id="fileInput"
                        type="file"
                        accept="image/jpeg, image/png, image/gif" // Only allow specified image file types
                        onChange={e => setBannerFile(e.target.files[0])}
                      />
                    </HStack>
                  </FormControl>

                  <FormControl>
                    <HStack>
                      <FormLabel>Upload Logo</FormLabel>

                      <input
                        id="fileInputs"
                        type="file"
                        accept="image/jpeg, image/png, image/svg+xml, image/x-icon"
                        onChange={e => setLogoFile(e.target.files[0])}
                      />
                    </HStack>
                  </FormControl>

                  {/* course Facilities */}
                  <FormLabel>Course Facilities</FormLabel>
                  <VStack align="flex-start" spacing={4}>
                    {courseFacilities &&
                      courseFacilities.length > 0 &&
                      courseFacilities.map((item, index) => (
                        <HStack key={index} bg={'gray.100'}>
                          <Text>{item}</Text>
                          <IconButton
                            icon={<CloseIcon />}
                            variant="ghost"
                            colorScheme="red"
                            aria-label="Delete Item"
                            onClick={() => removeItem(index)}
                          />
                        </HStack>
                      ))}
                    <HStack>
                      <Input
                        placeholder="Add new item"
                        value={newItem}
                        onChange={e => setNewItem(e.target.value)}
                      />
                      <IconButton
                        icon={<AddIcon />}
                        colorScheme="teal"
                        aria-label="Add Item"
                        onClick={addItem}
                      />
                    </HStack>
                  </VStack>

                  {/* Certification Checkbox */}
                  <Field name="certification" type="checkbox">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.certification &&
                          form.touched.certification
                        }
                      >
                        <Checkbox {...field} defaultChecked={true}>
                          Do you Want to certify this course?
                        </Checkbox>
                        <FormErrorMessage>
                          {form.errors.certification}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Spacer />
                  <DisplayFormValues />
                </VStack>

                <VStack spacing="6" width={'50%'}>
                  {/* Course Duration (Hour) */}
                  <Field name="courseDurationHour">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseDurationHour &&
                          form.touched.courseDurationHour
                        }
                      >
                        <FormLabel>Course Duration (Hour)</FormLabel>
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter duration in hours"
                        />
                        <FormErrorMessage>
                          {form.errors.courseDurationHour}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Course Class Interval */}
                  <Field name="courseClassInterval">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.courseClassInterval &&
                          form.touched.courseClassInterval
                        }
                      >
                        <FormLabel>Course Description</FormLabel>
                        <Textarea
                          {...field}
                          type="text"
                          placeholder="Enter class interval in minutes"
                        />
                        <FormErrorMessage>
                          {form.errors.courseClassInterval}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Original Price */}
                  <Field name="originalPrice">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.originalPrice &&
                          form.touched.originalPrice
                        }
                      >
                        <FormLabel>Original Price</FormLabel>
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter original price"
                        />
                        <FormErrorMessage>
                          {form.errors.originalPrice}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Offer Price */}
                  <Field name="offerPrice">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.offerPrice && form.touched.offerPrice
                        }
                      >
                        <FormLabel>Offer Price</FormLabel>
                        <Input
                          {...field}
                          type="number"
                          placeholder="Enter offer price"
                        />
                        <FormErrorMessage>
                          {form.errors.offerPrice}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* Adv Quote */}
                  <Field name="advQuote">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.advQuote && form.touched.advQuote
                        }
                      >
                        <FormLabel>Adv Quote</FormLabel>
                        <Input
                          {...field}
                          placeholder="Enter advertisement quote"
                        />
                        <FormErrorMessage>
                          {form.errors.advQuote}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {/* upload video   */}
                  <HStack justifyContent={'space-evenly'} width={'100%'}>
                    <FormControl>
                      <HStack>
                        <FormLabel>Upload Video</FormLabel>

                        <input
                          id="videoUpload"
                          name="video"
                          type="file"
                          accept="video/mp4, video/avi, video/mov"
                          onChange={e => setVideoFile(e.target.files[0])}
                        />
                      </HStack>
                    </FormControl>

                    <CircularProgress value={uploadProgress} color="green.400">
                      <CircularProgressLabel>{`${Math.round(
                        uploadProgress
                      )}%`}</CircularProgressLabel>
                    </CircularProgress>
                  </HStack>

                  {/* course Requirements */}
                  <FormLabel>Course Requirements</FormLabel>
                  <VStack align="flex-start" spacing={4}>
                    {courseRequirements &&
                      courseRequirements.length > 0 &&
                      courseRequirements.map((item, index) => (
                        <HStack key={index} bg={'gray.100'}>
                          <Text>{item}</Text>
                          <IconButton
                            icon={<CloseIcon />}
                            variant="ghost"
                            colorScheme="red"
                            aria-label="Delete Item"
                            onClick={() => removeRequirements(index)}
                          />
                        </HStack>
                      ))}
                    <HStack>
                      <Input
                        placeholder="Add new item"
                        value={newItemRequirement}
                        onChange={e => setNewItemRequirement(e.target.value)}
                      />
                      <IconButton
                        icon={<AddIcon />}
                        colorScheme="teal"
                        aria-label="Add Item"
                        onClick={addItemRequirememnt}
                      />
                    </HStack>
                  </VStack>

                  {/* mentor handling  */}
                  <MentorList setSelectedMentor={setSelectedMentor}>
                    <Box p={6} boxShadow={'lg'} rounded={'md'} width={'25rem'}>
                      <FormControl>
                        {mentorId ? (
                          <FormLabel>
                            Mentor Section : Mentor is selected you can Proceed
                          </FormLabel>
                        ) : (
                          <FormLabel>Mentor Section</FormLabel>
                        )}
                        {selectedMentor ? (
                          <>
                            <Text>
                              <strong>Selected Mentor :</strong>
                              {selectedMentor}{' '}
                            </Text>{' '}
                            <Button
                              leftIcon={<MdOutlineLibraryAdd />}
                              colorScheme="blue"
                              variant="outline"
                            >
                              Change Mentor
                            </Button>
                          </>
                        ) : (
                          <Button
                            leftIcon={<MdOutlineLibraryAdd />}
                            colorScheme="blue"
                            variant="outline"
                          >
                            Add Mentor
                          </Button>
                        )}
                      </FormControl>
                    </Box>
                  </MentorList>
                </VStack>
              </HStack>

              <Button
                type="submit"
                isLoading={isSubmitting}
                width={'40%'}
                mt={5}
              >
                Submit
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
      <Toaster />
    </Box>
  );
};

export default UpdateCourse;
