import React, { useContext, useEffect } from 'react';
import CouponContext from '../../../context/CouponContext';
import { Text } from '@chakra-ui/react';
import { format } from 'date-fns';


function AboutCoupon() {
  /* The line `const { couponData } = useContext(CouponContext);` is using the `useContext` hook in React
  to access the data stored in the `CouponContext`. */
  const { couponData } = useContext(CouponContext);

  /* The `useEffect` hook in React is used to perform side effects in function components. In this
  specific code snippet: */
  useEffect(() => {
    if (couponData) {
      console.log('couponData', couponData);
    }
  }, [couponData]);
  return (
    <>
      {couponData && couponData.code ? (
        <>
          <Text>
            <strong> Code : </strong> {couponData.code}
          </Text>
          <Text>
            <strong> Expiry : </strong> {format(new Date(couponData.expiry), 'dd-MM-yyyy')}
          </Text>
          <Text>
            <strong> MaxDiscount : </strong> {couponData.maxDiscount}
          </Text>
          <Text>
            <strong> Percentage : </strong> {couponData.percentage}
          </Text>
        </>
      ) : (
        <Text>select any coupon</Text>
      )}
    </>
  );
}

export default AboutCoupon;
