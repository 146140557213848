import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Text,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import toast from 'react-hot-toast';
import axios from 'axios';
import EnvContext from '../../context/EnvContext';
import { UserList } from './users/UserList';
import AuthContext from '../../context/AuthContext';
import { ChevronDownIcon } from '@chakra-ui/icons';
import PieChartComponent from '../user/authentication/charts/PieChartComponent ';
import CourseContext from '../../context/CourseListContext';
import BarChartComponent from '../user/authentication/charts/BarChartComponent ';
import { useNavigate } from 'react-router-dom';

export const AdminHome = () => {
  const { backendUrl } = useContext(EnvContext);
  const { courses } = useContext(CourseContext);
  const { getBlockUser, blockedUsers, getSpecificUsers, loading, users } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    getBlockUser();
  }, []);

  useEffect(() => {
    if (role !== "admin") navigate('/dashboard');
  }, [role]);

  //for bar graph
  const countEnrollments = () => {
    const enrollments = {};

    courses.forEach(course => {
      enrollments[course._id] = 0;
    });

    users.forEach(user => {
      user.courses.forEach(course => {
        enrollments[course?.course?._id]++;
      });
    });

    return enrollments;
  };

  const enrollments = countEnrollments();

  const data = Object.keys(enrollments).map(courseId => ({
    courseName: courses?.find(course => course?._id === courseId)?.courseName,
    enrollmentCount: enrollments[courseId],
  }));

  // for pie chart
  const countUserTypes = () => {
    const totalUsers = users.length;
    const totalBlockedUsers = blockedUsers.length;

    return [
      { name: 'Users', value: totalUsers },
      { name: 'Blocked Users', value: totalBlockedUsers },
    ];
  };

  const COLORS = ['#0088FE', '#FF8042'];
  const userTypes = countUserTypes();
  return (
    <>
      <Box px={'5rem'} mt={'2rem'}>
        <HStack w={'100%'} justify={'space-evenly'}>
          <PieChartComponent data={userTypes} COLORS={COLORS} />
          <BarChartComponent datas={data} />
        </HStack>

        <Tabs>
          <TabList>
            <Tab>User List</Tab>
            <Tab>Block List</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <UserList users={users} loading={loading} />
            </TabPanel>
            <TabPanel>
              <UserList users={blockedUsers} loading={loading} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};
