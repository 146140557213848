import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../context/AuthContext';
import {
  HStack,
  Text,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';
import CourseContext from '../../../context/CourseListContext';
import axios from 'axios';
import EnvContext from '../../../context/EnvContext';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const HandleCourse = () => {
  /* These lines of code are using the `useContext` hook from React to access the values stored in
  different contexts within the application. */
  const { backendUrl } = useContext(EnvContext);
  const { userData, handleUserData } = useContext(AuthContext);
  const { fetchData, courses } = useContext(CourseContext);
  const [showCourses, setShowCourses] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userData && Array.isArray(userData.courses)) {
      const assignedCourses = userData?.courses?.map(
        el => el?.course?.courseName
      );

      // Filter out assigned courses from all courses
      const unassignedCourses = courses.filter(
        course => !assignedCourses.includes(course.courseName)
      );

      setShowCourses(unassignedCourses);
    }
  }, [courses, userData]);

  useEffect(() => {
    if (showCourses) console.log('showCourses', showCourses);
  }, [showCourses]);
  const formatDate = dateString => {
    if (!dateString) return ''; // Return empty string if date string is not provided

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    // Get the date in YYYY-MM-DD format
    return date.toISOString().split('T')[0];
  };
  useEffect(() => {
    if (userData) {
      console.log('userData', userData);
    }
  }, [userData]);
  const role = localStorage.getItem('role');

  const navigate = useNavigate();

  useEffect(() => {
    if (role !== 'admin') navigate('/dashboard');
  }, [role]);

  /**
   * The function `addCourseManually` adds a course to a user's profile after confirming the action and
   * displaying loading and success/error toasts.
   * @param courseId - The `courseId` parameter in the `addCourseManually` function represents the
   * unique identifier of the course that you want to add to a user's profile. This identifier is used
   * to specify which course is being added to the user's account.
   * @param courseName - The `courseName` parameter in the `addCourseManually` function represents the
   * name of the course that you want to add to a user's account. It is used to display the course name
   * in the confirmation message before adding the course.
   * @returns The `addCourseManually` function returns a Promise.
   */
  const addCourseManually = async (courseId, courseName) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    const confirmed = window.confirm(
      'Are you sure to add this ' + courseName + ' to "' + userData.name + '" ?'
    );

    // Check if user confirmed
    if (!confirmed) return;

    const addCoursePromise = axios.post(
      `${backendUrl}/user/course?courseId=${courseId}&userId=${userData._id}`,
      null,
      { headers: headers }
    );
    toast.promise(addCoursePromise, {
      loading: {
        title: 'Course is adding up ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Course added successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to add course!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });

    try {
      const res = await addCoursePromise;
      console.log(res);
      handleUserData(userData._id);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * The function `deleteCourseManually` deletes a course by sending a request to the backend API with
   * authorization headers and displays a toast notification based on the response.
   * @param courseId - The `courseId` parameter in the `deleteCourseManually` function is the unique
   * identifier of the course that you want to delete. It is used to specify which course should be
   * deleted from the system.
   * @param courseName - The `courseName` parameter in the `deleteCourseManually` function represents the
   * name of the course that is being deleted. It is used to display a confirmation message to the user
   * before proceeding with the deletion.
   * @returns The `deleteCourseManually` function returns a Promise.
   */
  const deleteCourseManually = async (courseId, courseName) => {
    const token = localStorage.getItem('token');
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    let loadingToast;
    const confirmed = window.confirm(
      'Are you sure to delete this ' +
        courseName +
        ' to "' +
        userData.name +
        '" ?'
    );

    // Check if user confirmed
    if (!confirmed) return;
    const deleteCoursePromise = axios.post(
      `${backendUrl}/user/course?courseId=${courseId}&userId=${userData._id}`,
      null,
      { headers: headers }
    );
    toast.promise(deleteCoursePromise, {
      loading: {
        title: 'Course is deleting up ...',
        status: 'info',
        isClosable: false,
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      success: {
        title: 'Course deleted successfully!',
        status: 'success',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
      error: {
        title: 'Failed to delete course!',
        status: 'error',
        position: 'top-right',
        containerStyle: {
          marginTop: '62px',
        },
        duration: 1000,
      },
    });

    try {
      const res = await deleteCoursePromise;
      console.log(res);
      handleUserData(userData._id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <HStack w={'100%'} gap={'0.5rem'} alignItems={'flex-start'}>
        {/* The code snippet you provided is rendering a section of the user interface for displaying
        active courses. Here's a breakdown of what the code is doing: */}
        <VStack w={'60%'} borderRight={'1px solid'} borderColor={'gray.100'}>
          <Text fontWeight={700} fontSize={'large'}>
            Active Courses
          </Text>
          <TableContainer width={'90%'}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th w={'3rem'}>Sl. No.</Th>
                  <Th w={'60%'}>Course Name</Th>
                  <Th>Started Date</Th>
                  <Th>Delete</Th>
                </Tr>
              </Thead>
              <Tbody>
                {userData &&
                  userData.courses &&
                  Array.isArray(userData.courses) &&
                  userData.courses.length > 0 &&
                  userData.courses.map((el, index) => (
                    <Tr key={index}>
                      <Td>{index + 1}</Td>
                      <Td>{el?.course?.courseName}</Td>
                      <Td>{formatDate(el.addedDate)}</Td>
                      <Td>
                        <IconButton
                          icon={<DeleteIcon />}
                          aria-label="Delete"
                          colorScheme="red"
                          ml={2}
                          onClick={() =>
                            deleteCourseManually(
                              el?.course?._id,
                              el?.course?.courseName
                            )
                          }
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        </VStack>

        {/* The code snippet you provided is rendering a section of the user interface for adding
       courses. Here's a breakdown of what the code is doing: */}
        <VStack w={'40%'}>
          <Text fontWeight={700} fontSize={'large'}>
            Add Courses
          </Text>
          <VStack width={'100%'}>
            {showCourses &&
              Array.isArray(showCourses) &&
              showCourses.length > 0 &&
              showCourses.map((el, i) => (
                <HStack
                  width={'100%'}
                  key={i}
                  justify={'space-between'}
                  px={'10rem'}
                >
                  <Text>
                    {i + 1}) {el.courseName}
                  </Text>

                  <IconButton
                    icon={<AddIcon />}
                    aria-label="add"
                    colorScheme="gray"
                    ml={2}
                    onClick={() => addCourseManually(el._id, el.courseName)}
                  />
                </HStack>
              ))}
          </VStack>
        </VStack>
      </HStack>
    </>
  );
};

export default HandleCourse;
